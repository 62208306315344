<template>
  <div class="postcodePrefilter postcodePrefilter--searchWithFinance">
    <div class="postcodePrefilter__form">
      <InputPostcode
        v-model="radius"
        rules="required|postcode_prefilter"
        @errors="setErrors"
      />

      <FinanceProductSummary v-if="hasFinance && !isPersonalised" />

      <div class="postcodePrefilter__buttons">
        <template v-if="hasFinance">
          <FinanceEditButton :is-disabled="isDisabled" @click="submit" />
          <FinanceAcceptButton
            is-primary
            :is-disabled="isDisabled"
            @click="submit"
          />
        </template>
        <OsButton
          v-else
          class="postcodePrefilter__button"
          is-primary
          :is-disabled="hasErrors"
          @click="submit"
        >
          Search
        </OsButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFinanceCriteriaStore } from '~/stores/finance/criteriaStore'
import { IFiltersRadius } from '~/types/filters'

const emit = defineEmits(['event'])
defineProps({
  hasFinance: {
    type: Boolean,
    required: false,
    default: true
  }
})

const radius = defineModel({
  type: Object as PropType<IFiltersRadius>,
  required: true
})

const financeCriteriaStore = useFinanceCriteriaStore()

const hasErrors = ref(false)
const isPersonalised = computed(() => financeCriteriaStore.isPersonalised)

const setErrors = (errors: string[]) => {
  hasErrors.value = (errors && !!errors.length) || false
}

const isDisabled = computed(
  () => radius.value.Postcode?.length === 0 || hasErrors.value
)

const submit = () => {
  if (hasErrors.value) return

  emit('event', false)
}
</script>

<style lang="scss">
.postcodePrefilter--searchWithFinance {
  box-shadow: $shadow;
  padding: rem(16);

  @include viewport('sm') {
    width: rem(410);
  }

  .postcodePrefilter__form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .distanceDropdown {
    margin-right: 0;
  }

  .errorText {
    margin: rem(6) 0 0;
  }
}

.postcodePrefilter__buttons {
  display: grid;
  grid-gap: rem(16);

  @include viewport('sm') {
    grid-template-columns: 1fr 1fr;
  }

  button {
    width: 100%;
  }
}
</style>

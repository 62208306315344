<template>
  <client-only>
    <section id="modelSearch" class="modelSearchPrefilter">
      <div class="modelSearchPrefilter__search">
        <h2 v-if="title">{{ title }}</h2>

        <FilterModel
          :vehicle-condition="vehicleCondition"
          :tracking-options="{
            pageCategory: 'select model',
            category: 'select model'
          }"
          :restricted-models="restrictedModels"
          :selected-model-ids="selectedModels"
          :popular-models="popularModels"
          @select-model="selectModel"
        />

        <OsButtonTray
          v-if="
            isTevOnly ||
            selectedModels.length ||
            !restrictedModels.length ||
            (isNew && selectedModels.length)
          "
          class="button-tray--restricted mt-4"
        >
          <OsButton
            v-if="
              (isTevOnly && selectedModels.length) ||
              !restrictedModels.length ||
              selectedModels.length
            "
            :is-primary="isTevOnly ? false : true"
            :has-shadow="false"
            :is-external-link="false"
            icon-side="left"
            :theme="EButtonThemes.cart"
            :aria-label="body || 'View Results'"
            @click.stop="applyModelFilter"
          >
            <OsIcon
              name="Cart"
              :class="isTevOnly ? 'text-blue-600' : 'text-white'"
            />
            {{ body || 'View Results' }}
          </OsButton>
          <OsButton
            v-if="isTevOnly"
            :is-primary="true"
            :has-shadow="false"
            :is-external-link="false"
            @click.stop="notifyMe"
          >
            Notify me
          </OsButton>
        </OsButtonTray>
        <div v-else-if="selectedModels.length > 0" class="row mt-4">
          <HomeModelSearchPostcode
            v-model="selectedRadius"
            :has-finance="!customerTypeStore.isMotability"
            @event="applyModelFilter()"
          />
        </div>
      </div>
    </section>
  </client-only>
</template>

<script lang="ts">
import { ERouteConditions, ERoutes } from '~/types/routes'
import { useFiltersStore } from '~/stores/filtersStore'
import { useCustomerTypeStore } from '~/stores/customerTypeStore'
import { IFilterDataBrandModel } from '~/types/filterData'
import { useStockNotificationStore } from '~/stores/forms/stockNotificationStore'
import { ECondition } from '~/types/vehicle'
import { EButtonThemes } from '~/components/base/Button.vue'

export default defineComponent({
  props: {
    restrictedModels: {
      type: Array as PropType<number[]>,
      required: false,
      default: () => []
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    body: {
      type: String,
      required: false,
      default: ''
    },
    isTevOnly: {
      type: Boolean,
      default: false,
      required: false
    },
    popularModels: {
      type: Array as PropType<string[]>,
      default: () => [],
      required: false
    }
  },
  setup(props) {
    const isKioskMode = useState('kioskmode')

    const { $dataLayer } = useNuxtApp()
    const router = useRouter()
    const route = useRoute()
    const filtersStore = useFiltersStore()
    const customerTypeStore = useCustomerTypeStore()
    const stockNotificationStore = useStockNotificationStore()

    const activeFilters = computed(() => filtersStore.active)
    const vehicleCondition = computed(() =>
      convertCondition.fromConditonRouteParam(
        route.params.condition as ERouteConditions
      )
    )

    const {
      filters,
      toggleModel,
      isNew,
      updateModels,
      updateCondition,
      updateRetailer,
      updateRadius,
      saveFilters
    } = useFilters({
      ...activeFilters.value,
      ...(props.restrictedModels?.length
        ? { models: [props.restrictedModels[0]] }
        : {})
    })

    const selectedModels = computed(() => filters.value.models)

    const selectModel = (model: IFilterDataBrandModel) => {
      toggleModel(model.Id)
    }

    const selectedRadius = computed({
      get() {
        return filters.value.radius
      },
      set(val) {
        updateRadius(val, true)
      }
    })

    const notifyMe = () => {
      // TODO: we should only save filters, we do this to run the colaterals of updateModels
      updateModels(filters.value.models, true)

      stockNotificationStore.updatePreselectionWithFilters(filters.value)

      router.push({
        ...route,
        name: ERoutes.StockNotification
      })

      $dataLayer.linkClick({
        category: 'tev_home',
        action: 'callback'
      })
    }

    const applyModelFilter = async () => {
      if (props.isTevOnly) {
        updateCondition(ECondition.Used)

        updateRetailer(null)
      }

      await saveFilters()

      $dataLayer.linkClick({
        category: 'select a model',
        action: 'search',
        forms: [
          {
            name: 'select a model',
            event: 'submit',
            value: filtersStore.getDescriptions('Models')
          }
        ]
      })

      const { params } = route

      router
        .push({
          name: ERoutes.Results,
          params: {
            condition: props.isTevOnly
              ? ERouteConditions.Used
              : params.condition,
            type: customerTypeStore.getCustomerTypeRouteParam
          },
          query: filtersStore.getActiveQueryParams
        } as any)
        .catch(() => {})
    }

    return {
      vehicleCondition,
      EButtonThemes,
      selectedModels,
      isNew,
      selectModel,
      applyModelFilter,
      customerTypeStore,
      notifyMe,
      isKioskMode,
      selectedRadius
    }
  }
})
</script>

<style lang="scss">
.modelSearchPrefilter {
  position: relative;
}

.modelSearchPrefilter__search {
  position: relative;
  max-width: $containerWidth;
  margin-left: auto;
  margin-right: auto;
  z-index: 3;

  h2 {
    margin-bottom: rem(24);
  }

  .modelSearch__filter__body {
    margin-right: rem(16);
  }
}

.modelSearch__action {
  display: flex;
  justify-content: flex-end;
  margin-top: rem(24);
  button {
    margin-right: rem(8);
  }
}
</style>
